<script setup lang="ts">
import { useDisplay } from 'vuetify'

const { mobile } = useDisplay()
</script>

<template>
  <v-card :elevation="10" color="grey-lighten-3" class="d-flex flex-column h-100 px-3 py-5 pb-md-5">
    <v-row class="ma-4">
      <v-col cols="12" md="2" class="text-center text-md-left">
        <Icon
          :size="mobile ? '5em' : '7em'"
          class="pa-2 mb-md-0 mb-2"
          style="background-color: rgba(255, 255, 255, 0.9); border-radius: 100%"
          name="system-uicons:download-alt"
        />
      </v-col>
      <v-col cols="12" md="10" class="d-flex flex-column text-center text-md-left">
        <h3 class="text-h4 text-uppercase pt-1">Downloads</h3>
        <NuxtLink to="/satzung" class="pt-5 link">
          <Icon name="system-uicons:chevron-right-double"></Icon>
          Satzung
        </NuxtLink>
        <NuxtLink to="/vergaberichtlinien" class="pt-1 link">
          <Icon name="system-uicons:chevron-right-double"></Icon>
          Vergaberichtlinien
        </NuxtLink>
        <NuxtLink
          to="https://antragsportal.buergerstiftung-solingen.de"
          class="pt-1 link"
          target="_blank"
        >
          <Icon name="system-uicons:chevron-right-double"></Icon>
          Förderantrag
        </NuxtLink>
      </v-col>
    </v-row>
  </v-card>
</template>

<style scoped lang="scss">
.link {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
    text-decoration-color: rgb(var(--v-theme-primary));
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
  }
}
</style>
