<template>
  <v-container>
    <h2 class="pt-16 text-h2 text-right text-uppercase">
      Die Stiftung<br />
      Informationen & Spenden
    </h2>
    <v-row class="mt-6" align="stretch">
      <v-col cols="12" md="6">
        <RathausCard
          title="Sie haben Fragen?"
          icon="system-uicons:create"
          text="Wenn Sie mehr Informationen oder einen persönlichen Austausch brauchen, unterstützt Sie unsere Geschäftsführung."
          color="primary"
          route="/kontakt"
        />
      </v-col>
      <v-col cols="12" md="6">
        <RathausCard
          title="Sie möchten spenden?"
          icon="tabler:question-mark"
          text="Mit Ihrer Unterstützung tragen Sie zur Umsetzung von Projekten bei, welche die Lebensqualität vieler Menschen verbessern. Oder Sie unterstützen Menschen in Notlagen. "
          color="grey"
          route="/spenden"
          button-text="Jetzt spenden"
        />
      </v-col>

      <v-col cols="12">
        <DownloadCard />
      </v-col>
    </v-row>
  </v-container>
</template>
