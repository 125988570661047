<script setup lang="ts">
import { useDisplay } from 'vuetify'
import type Slide from '~~/types/slider/Slide'
const { smAndUp } = useDisplay()

const slides = ref<Slide[]>([
  {
    image: 'images/slider/Fuesse.png',
    title: 'FONDS HEIMAT 2025',
    alt: 'Vereine in den Solinger Stadtteilen können ab sofort Anträge für Projekte zur Brauchtumspflege stellen',
    text: 'Vereine in den Solinger Stadtteilen können ab sofort Anträge für Projekte zur Brauchtumspflege stellen!',
    button: {
      text: 'mehr erfahren',
      link: '/aktuelles/fonds-heimat-2025'
    },
    style: 'background: #0f1f5e !important;'
  },
  {
    image: '/images/slider/2.jpg',
    alt: 'Gerd Kaimer',
    title: 'WIR HELFEN VON HERZEN',
    text: 'Wir unterstützen Menschen, die in Not geraten sind, und Projekte gemeinnütziger Organisationen, die unserer Stadt guttun.',
    button: {
      text: 'mehr erfahren',
      link: '/wir/stiftung'
    },
    style: `background: rgba(206, 117, 48, 1) !important;
      background: linear-gradient(
                155deg,
                rgba(206, 117, 48, 1) 23%,
                rgba(250, 209, 9, 1) 100%
              ) !important;`
  }
])

const containerRef = ref(null)

const swiper = useSwiper(containerRef, {
  effect: 'creative',
  loop: true,
  autoplay: {
    delay: 10000
  },
  creativeEffect: {
    prev: {
      shadow: true,
      translate: [0, 0, -400]
    },
    next: {
      shadow: true,
      translate: [0, 0, -400]
    }
  }
})

defineComponent({
  name: 'SliderComponent'
})
</script>

<template>
  <ClientOnly>
    <swiper-container ref="containerRef" :init="false">
      <swiper-slide v-for="(slide, idx) in slides" :key="idx">
        <v-row style="height: 500px">
          <v-col style="height: 100%" class="pa-0" cols="12" sm="8" md="7" lg="5">
            <v-card
              style="height: 100%"
              :style="slide.style"
              :elevation="0"
              class="text-white d-flex justify-end align-center"
            >
              <div style="max-width: 450px" class="px-3 ma-10 ma-sm-0">
                <h1 class="text-h2 mt-0 mb-5 text-uppercase hyphens text-wrap">
                  {{ slide.title }}
                </h1>
                <p class="text-white text-body-1 pb-6">
                  {{ slide.text }}
                </p>

                <NuxtLink
                  style="text-decoration: none"
                  :to="slide.button.link"
                  :target="index === 1 ? '_blank' : ''"
                >
                  <v-btn
                    class="mt-5 py-1 d-flex align-center"
                    color="primary"
                    variant="flat"
                    aria-label="Mehr Erfahren"
                    append-icon="system-uicons:arrow-right"
                  >
                    <span
                      aria-label="Mehr Erfahren"
                      class="text-body-1 font-weight-bold px-2 hyphens"
                    >
                      {{ slide.button.text }}
                    </span>
                  </v-btn>
                </NuxtLink>
              </div>
            </v-card>
          </v-col>
          <v-col class="pa-0 h-100" cols="0" sm="4" md="5" lg="7">
            <NuxtImg
              :src="slide.image"
              style="min-height: 100%; min-width: 100%"
              width="1200px"
              height="803px"
              sizes="1px sm:300px md:400px lg:700px xl:1024px xxl:1200px xxxl:1400px"
              format="webp"
              :alt="slide.alt"
              fit="cover"
              loading="lazy"
              preload
              placeholder
            />
            <!-- <v-img style="height: 100%" :alt="slide.alt" cover :src="slide.image" /> -->
          </v-col>
        </v-row>
      </swiper-slide>
    </swiper-container>
  </ClientOnly>
</template>
