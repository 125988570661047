<script setup lang="ts">
import { useDisplay } from 'vuetify'

const { mdAndUp } = useDisplay()
</script>

<template>
  <v-container class="mt-16">
    <h2 class="pt-16 text-h2 text-uppercase pb-8 pb-md-0" :class="{ sticky: mdAndUp }">
      Klingenstadt<br />
      Solingen
    </h2>
    <v-row justify="end">
      <v-col cols="12" md="8">
        <h3 class="text-uppercase text-h4">Werkstattregion im Grünen</h3>
        <p class="text-justify text-body-1 pt-2 pb-6">
          Solingen liegt gemeinsam mit Remscheid und Wuppertal im Bergischen Land, einer
          traditionellen Handwerks- und Industrieregion. Im Radius von 30 bis 50 km liegen die
          Metropolen wie Düsseldorf und Köln, Essen und Duisburg. Der nächste internationale
          Flughafen (DUS) ist in weniger als 30 Minuten erreichbar.
        </p>
      </v-col>
      <v-col cols="12" md="8">
        <h3 class="text-uppercase text-h4">Hofschaften und Höhenzüge</h3>
        <p class="text-justify text-body-1 pt-2 pb-6">
          Dass Solingen eine alte Industriestadt ist, fällt im Stadtbild kaum auf: Die Stadt ist
          grün, ihr Wohnwert hoch. Ein historisches Stadtzentrum suchen Besucher allerdings auch
          vergebens. Solingen ist vielmehr ein Kaleidoskop vieler kleinstädtischer Siedlungskerne
          und ländlicher Hofschaften auf ein paar Höhenzügen, die entlang der Ausfallstraßen
          miteinander verwuchsen und erst 1929 vom preußischen Staat im Zuge einer Kommunalreform
          zusammengefasst wurden. Dazwischen liegen Bachtäler, die niemals völlig kultiviert wurden
          und heute als grüne Lungen das Stadtgebiet gliedern. Im Osten und Süden rahmen die
          bewaldeten und zerklüfteten Wupperberge die Stadt ein.
        </p>
      </v-col>
      <v-col cols="12" md="8">
        <h3 class="text-uppercase text-h4">Menschen aus 140 Nationen</h3>
        <p class="text-justify text-body-1 pt-2 pb-16">
          Toleranz und Vielfalt haben Tradition. In Solingen leben Menschen aus 140 Nationen. Sie
          kommen aus Europa, Afrika, Asien, Amerika und Australien - Mitbürger:innen, die teils
          schon seit vielen Jahren in Solingen leben.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
