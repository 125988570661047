<script setup lang="ts">
import { useDisplay } from 'vuetify'
const { mobile } = useDisplay()

const properties = defineProps({
  title: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: 'grey'
  },
  route: {
    type: String,
    default: '/'
  },
  buttonText: {
    type: String,
    default: 'Mehr erfahren'
  }
})

const backgroundColor = computed(() => {
  return properties.color === 'primary' ? `rgb(var(--v-theme-${properties.color}))` : '#646d74'
})
</script>

<template>
  <v-card
    :elevation="10"
    class="d-flex flex-column h-100 px-3 py-5 pb-md-5"
    min-height="250px"
    :style="{ backgroundColor: backgroundColor }"
  >
    <v-row class="ma-4">
      <v-col cols="12" md="3" class="text-center text-md-left">
        <Icon
          :size="mobile ? '5em' : '7em'"
          class="pa-2 mb-md-0 mb-2"
          style="background-color: rgba(255, 255, 255, 0.9); border-radius: 100%"
          :name="properties.icon"
        />
      </v-col>
      <v-col cols="12" md="9" class="d-flex flex-column text-center text-md-left">
        <h3
          class="text-h4 text-uppercase pl-md-8 pt-1"
          :class="{
            'text-black': properties.color === 'primary',
            'text-white': properties.color === 'grey'
          }"
        >
          {{ properties.title }}
        </h3>
        <p
          class="text-body-1 pl-md-8 pt-5"
          :class="{
            'text-black': properties.color === 'primary',
            'text-white': properties.color === 'grey'
          }"
        >
          {{ properties.text }}
        </p>
      </v-col>
      <v-col
        cols="12"
        class="pt-8"
        :class="{
          'text-accent': properties.color === 'primary',
          'text-black': properties.color === 'grey'
        }"
      >
        <div class="d-flex align-end justify-center justify-md-end h-100">
          <v-btn
            aria-label="Mehr erfahren"
            variant="flat"
            :color="properties.color === 'primary' ? '#646d74' : 'primary'"
            :to="properties.route"
          >
            {{ properties.buttonText }}
            <Icon class="ml-1" size="1.5em" name="system-uicons:arrow-right-circle"></Icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
