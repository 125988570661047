import { computed, nextTick, onMounted, watch } from "vue";
export function useSwiper(swiperContainerRef, options) {
  const swiper = computed(() => swiperContainerRef?.value?.swiper ?? null);
  const next = (...params) => {
    if (!swiper.value)
      return;
    if (params.length === 0)
      swiper.value.slideNext();
    else
      swiper.value.slideNext(...params);
  };
  const to = (...params) => {
    if (!swiper.value)
      return;
    swiper.value.slideTo(...params);
  };
  const reset = (...params) => {
    if (!swiper.value)
      return;
    if (params.length === 0)
      swiper.value.slideReset();
    else
      swiper.value.slideReset(...params);
  };
  const prev = (...params) => {
    if (!swiper.value)
      return;
    if (params.length === 0)
      swiper.value.slidePrev();
    else
      swiper.value.slidePrev(...params);
  };
  const checkSwiperRef = () => {
    const isSwiperContainer = swiperContainerRef.value?.nodeName === "SWIPER-CONTAINER";
    if (!isSwiperContainer && swiper.value !== null && !options) {
      console.warn(
        '"useSwiper()" requires a ref and is tied to the %c`<swiper-container ref="swiperContainerRef"></swiper-container>` element.',
        "font-weight: bold;"
      );
    }
  };
  const initialize = () => {
    if (swiperContainerRef.value && options !== void 0) {
      Object.assign(swiperContainerRef.value, options);
      swiperContainerRef.value?.initialize();
    }
  };
  watch(swiper, () => checkSwiperRef());
  onMounted(() => nextTick(() => initialize()));
  return {
    instance: swiper,
    next,
    prev,
    to,
    reset
  };
}
