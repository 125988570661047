<script setup lang="ts">
useSeoMeta({
  title: 'Startseite',
  ogTitle: 'Gerd Kaimer Bürgerstiftung |  Startseite',
  description:
    'Seit 2018 fördert die Gerd-Kaimer-Stiftung Projekte in Solingen. Wir stärken die Stadt und ihre Bewohner durch Bürgerengagement und großzügige Spenden.',
  ogDescription:
    'Unsere Stiftung wurde 2018 von der Stadt Solingen gegründet. Benannt wurde sie nach dem ehemaligen Oberbürgermeister Gerd-Kaimer. Als Bürgerstiftung unterstützen wir Vorhaben, die unsere Klingenstadt voranbringen. Wir sind dankbar, dass es Menschen gibt, die spenden und damit viel für die Stadt und ihre Bewohnerinnen leisten.'
})

defineComponent({
  name: 'IndexPage'
})
</script>

<template>
  <div>
    <Slider />
    <NewsSection />
    <RathausSection class="mt-16" />
    <SolingenSection class="mt-16" />
  </div>
</template>

<style scoped lang="scss">
.sticky {
  position: sticky;
  top: 10px;
}
</style>
